<template>
  <tr @click='fundDetailsOpen(fund)'>
    <td></td>
    <td>{{ fund.name }}</td>
    <td>{{ fund.short_name }}</td>
    <td>{{ fund.fund_code }}</td>
    <td>{{ fund.ksd_fund_code }}</td>
    <td>{{ fund.start_date }}</td>
    <td>{{ fund.end_date }}</td>
    <td>{{ fund.entity_name }}</td>
    <td>{{ fund.contact_name }}</td>
    <td>{{ fund.phone_number }}</td>
    <td>{{ fund.account_name }}</td>
    <td>{{ fund.account_number }}</td>
    <td>{{ fund.target_asset }}</td>
    <td>{{ isPublicOfferingString }}</td>
    <td>{{ isIpoParticipation }}</td>
    <td>{{ fund.strategy }}</td>
    <td>{{ managers }}</td>
    <td>{{ fund.target_investment.join(', ') }}</td>
    <td>{{ fund.non_marketable_objectives }} %</td>
    <td>{{ fund.proposal_weight }} %</td>
  </tr>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FundRow',
  props: [
    'fund'
  ],
  computed: {
    ...mapState('users', [
      'users',
    ]),
    isPublicOfferingString () {
      return this.fund.is_public_offering ? '공모' : ''
    },
    isIpoParticipation () {
      return this.fund.is_ipo_participation ? '참여' : ''
    },
    managers () {
      if (this.fund.main_manager_name && this.fund.sub_manager_name) {
        return `(정) ${this.fund.main_manager_name} (부) ${this.fund.sub_manager_name}`
      }
      else if (this.fund.main_manager_name) {
        return `(정) ${this.fund.main_manager_name}`
      }
      else if (this.fund.sub_manager_name) {
        return `(부) ${this.fund.sub_manager_name}`
      }
      else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions('funds', [
      'fundDetailsOpen',
    ]),
  }
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pl-2 pr-6 border-b cursor-pointer text-gray-700 whitespace-no-wrap;
  }

</style>
