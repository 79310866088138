<template>
  <div class='pb-8 pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Funds</h1>
      </div>
    </portal>
    <div class='flex flex-row my-6'>
      <button
        class='border p-3 rounded-md border-gray-50 text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='addNewFund()'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;'></plus-circle-icon>
        Add Fund
      </button>
      <el-radio-group
        v-model='accountType'
        size='large'
        class='ml-3 mt-1'>
        <el-radio-button label='전체'></el-radio-button>
        <template v-for='type in accountTypes'>
          <el-radio-button :label='type' :key='type'></el-radio-button>
        </template>
      </el-radio-group>
    </div>
    <div class='funds-table-container overflow-auto'>
      <table class='table-fixed text-xs lg:text-sm'>
        <thead>
          <tr class='text-left'>
            <th>계좌구분</th>
            <th @click='sort("name")'>펀드명</th>
            <th @click='sort("short_name")'>펀드약칭</th>
            <th @click='sort("fund_code")'>펀드코드</th>
            <th @click='sort("ksd_fund_code")'>얘탁원 펀드코드</th>
            <th @click='sort("start_date")'>설정일</th>
            <th @click='sort("end_date")'>만기일</th>
            <th @click='sort("entity_name")'>수탁은행</th>
            <th @click='sort("contact_name")'>담당자</th>
            <th @click='sort("phone_number")'>연락처</th>
            <th @click='sort("account_name")'>수탁기관명</th>
            <th @click='sort("account_number")'>수탁기관코드</th>
            <th @click='sort("target_asset")'>투자구분</th>
            <th @click='sort("is_public_offering")'>공모여부</th>
            <th @click='sort("is_ipo_participation")'>IPO 참여여부</th>
            <th @click='sort("strategy")'>전략</th>
            <th @click='sort("main_manager_name")'>운용역</th>
            <th @click='sort("target_investment")'>주목적 투자</th>
            <th @click='sort("non_marketable_objectives")'>비시장성 목표</th>
            <th @click='sort("proposal_weight")'>종목별(제안서) 비중</th>
          </tr>
        </thead>
        <template v-for='(fundsData, targetAsset) in fundsByTargetAsset'>
          <tbody v-for='(funds, index) in fundsData' :key='`${targetAsset}-${index}`'>
            <tr class='font-bold'>
              <td>{{targetAsset}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <fund-row v-for='fund in funds' :key='`fund-row-${fund.id}`' :fund='fund' />
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FundRow               from '@/views/fund/FundRow.vue'
import { PlusCircleIcon }    from '@vue-hero-icons/outline'
import cloneDeep             from 'lodash/cloneDeep'

export default {
  name: 'Funds',
  components: {
    FundRow,
    PlusCircleIcon,
  },
  data () {
    return {
      sortDirections: {
        name: 'asc',
        short_name: 'asc',
        fund_code: 'asc',
        ksd_fund_code: 'asc',
        start_date: 'asc',
        end_date: 'asc',
        entity_name: 'asc',
        contact_name: 'asc',
        phone_number: 'asc',
        account_name: 'asc',
        target_asset: 'asc',
        is_public_offering: 'asc',
        main_manager_name: 'asc',
        target_investment: 'asc',
        non_marketable_objectives: 'asc',
        proposal_weight: 'asc',
        is_ipo_participation: 'asc',
      },
      sortInfo: {
        key: '',
        direction: ''
      },
      accountType: '전체',
    }
  },
  computed: {
    ...mapState('funds', [
      'funds',
      'emptyFund',
    ]),
    ...mapState('ipos', [
      'accountTypes',
    ]),
    fundsByTargetAsset () {
      if (this.accountType === '전체') {
        return this.funds.reduce((hsh, row) => {
          hsh[row.target_asset] ||= {}
          hsh[row.target_asset]['funds'] ||= []
          hsh[row.target_asset]['funds'].push(row)

          return hsh
        }, {})
      } else {
        return this.funds.filter(fund => fund.target_asset === this.accountType).reduce((hsh, row) => {
          hsh[row.target_asset] ||= {}
          hsh[row.target_asset]['funds'] ||= []
          hsh[row.target_asset]['funds'].push(row)

          return hsh
        }, {})
      }
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('funds', [
      'fundDetailsOpen',
      'getFunds',
    ]),
    ...mapMutations('funds', [
      'setFunds',
    ]),
    addNewFund () {
      this.fundDetailsOpen(cloneDeep(this.emptyFund))
    },
    sort (key) {
      this.sortInfo.direction = this.toggleSortDirection(key)
      this.sortInfo.key = key
      this.sortData(this.funds, this.sortInfo)
    },
    toggleSortDirection (key) {
      if (this.sortDirections[key] === 'asc') {
        this.sortDirections[key] = 'desc'
      } else {
        this.sortDirections[key] = 'asc'
      }
      return this.sortDirections[key]
    },
    sortData (funds, sortInfo) {
      let sortFunds = cloneDeep(funds)
      sortFunds.sort((aSort, bSort) => {
        let aValue, bValue
        if (typeof aSort === 'string') {
          aValue = aSort[sortInfo.key].toUpperCase()
          bValue = bSort[sortInfo.key].toUpperCase()
        } else {
          aValue = aSort[sortInfo.key]
          bValue = bSort[sortInfo.key]
        }

        if (sortInfo.direction === 'asc') {
          if (aValue < bValue) {
            return -1
          } else if (aValue > bValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (aValue < bValue) {
            return 1
          } else if (aValue > bValue) {
            return -1
          } else {
            return 0
          }
        }
      })
      this.setFunds(sortFunds)
    },
  },
  mounted () {
    this.getFunds()
  },
  beforeDestroy () {
    this.sidepanelClose()
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-3 pl-2 pr-3 border-b uppercase tracking-wide sticky top-0 whitespace-no-wrap;
    background-color: #FFF;
    white-space: nowrap;
    z-index: 2;
  }
  td {
    @apply py-3 pl-2 pr-2 border-b;
    white-space: nowrap;
  }

  .funds-table-container {
    height: calc(100vh - 12rem);
  }


  @media (min-width: 1024px) {
    .funds-table-container {
      height: calc(100vh - 14rem);
    }
  }
</style>
